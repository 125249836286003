jQuery(document).ready(($) => {
  /* =======================================================================
     Forum accordion
  ======================================================================= */
  $("#af-wrapper > .title-element").on("click", (e) => {
    $(e.currentTarget)
      .toggleClass("title-element--open")
      .next(".content-container")
      .slideToggle(300);
  });
});
